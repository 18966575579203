import React from 'react';
import faunadb from 'faunadb'; 
import client from './faunaService'; 
import './FormComponent.css';

const FormComponent = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      registerNo: event.target.registerNo.value,
      department: event.target.department.value,
      courseRegistered: event.target.courseRegistered.value,
      credits: event.target.credits.value,
      mode: event.target.mode.value
    };

    try {
      const response = await client.query(
        faunadb.query.Create(
          faunadb.query.Collection('Tasks'),
          { data: formData }
        )
      );
      console.log('Document created with ID: ', response.ref.id);
      event.target.reset();
    } catch (error) {
      console.error('Error creating document: ', error);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1>Student Registration Form</h1>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" placeholder="Name" required />
        </div>
        <div className="form-group">
          <label htmlFor="registerNo">Register No</label>
          <input type="text" id="registerNo" name="registerNo" placeholder="Register No" required />
        </div>
        <div className="form-group">
          <label htmlFor="department">Department</label>
          <input type="text" id="department" name="department" placeholder="Department" required />
        </div>
        <div className="form-group">
          <label htmlFor="courseRegistered">Course Registered</label>
          <input type="text" id="courseRegistered" name="courseRegistered" placeholder="Course Registered" required />
        </div>
        <div className="form-group">
          <label htmlFor="credits">Credits</label>
          <input type="text" id="credits" name="credits" placeholder="Credits" required />
        </div>
        <div className="form-group">
          <label htmlFor="mode">Mode</label>
          <input type="text" id="mode" name="mode" placeholder="Mode" required />
        </div>
        <center><button type="submit">Submit</button></center>
      </form>
    </div>
  );
};

export default FormComponent;
