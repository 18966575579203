import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAZ8P5Mi6q0dBhfD9LCF5lfr0Y36mcRQ5o",
    authDomain: "fauna-75430.firebaseapp.com",
    projectId: "fauna-75430",
    storageBucket: "fauna-75430.appspot.com",
    messagingSenderId: "271425581844",
    appId: "1:271425581844:web:5a161dbb6c7aee14bb4801"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore };

